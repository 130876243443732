import { takeLatest, call, all, put } from 'redux-saga/effects';
import {
  GET_ISO_COUNTRY_SUCCESS,
  GET_ISO_COUNTRY_REQUEST,
} from './isoCountry.types';
import errorHandler from 'utils/errorHandler';
import axios from 'axios';
export function* getisoCountry({ data }) {
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: `https://payhub.amanyaman.com/api/data/countryCodes`,
  };
  try {
    const { data } = yield call(axios, options);
    yield put({ type: GET_ISO_COUNTRY_SUCCESS, data });
  } catch (error) {
   errorHandler(error);
  }
}

export function* onVoucherPost() {
  yield takeLatest(GET_ISO_COUNTRY_REQUEST, getisoCountry);
}

export function* isoCountryWatcher() {
  yield all([call(onVoucherPost)]);
}
